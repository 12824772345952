import React, { useState } from 'react'
import { graphql } from 'gatsby'
import theme from '../helpers/theme'
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import IMG from '../components/image'
import SEO from '../components/seo'
import Book from '../components/book'
import LinkOut from '../components/link-out'
import PopUp from '../components/popup'

const LISTEN_LINKS = [
	{
		url: "https://soundcloud.com/ageofunion/",
		title: "SoundCloud",
		label: "Listen"
	},
	{
		url: "https://podcasts.apple.com/ca/podcast/age-of-union/id1527346168",
		title: "Apple Podcasts",
		label: "Listen"
	},
	{
		url: "https://open.spotify.com/show/3HH5PUVP5MbsEHkGTsTi04",
		title: "Spotify",
		label: "Listen"
	},
	{
		url: "https://www.stitcher.com/podcast/age-of-union",
		title: "Stitcher",
		label: "Listen"
	},
	{
		url: "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5zb3VuZGNsb3VkLmNvbS91c2Vycy9zb3VuZGNsb3VkOnVzZXJzOjg2MzEwMjMzOC9zb3VuZHMucnNz",
		title: "Google Podcasts",
		label: "Listen"
	}
]

const EBOOK_LINKS = [
	{
		url: "https://geni.us/FreeAgeOfUnioneBook",
		title: "Amazon Kindle",
		label: "Read now"
	},
	{
		url: "https://books.apple.com/us/book/id1504008229",
		title: "Apple iBooks",
		label: "Read now"
	},
	{
		url: "https://play.google.com/store/books/details?id=eD7YDwAAQBAJ",
		title: "Google Play",
		label: "Read now"
	},
]

const DownloadLink = (props) => (
	<div className="cols--baseline outer flex-between">
		<div className="w75">
			<h3 className="regcase">{props.title}</h3>
		</div>
		<div className="span-7">
			{props.url ? (
				<LinkOut href={props.url} className="h4 button">{props.label}</LinkOut>
			) : (
				<h4>{props.label}</h4>
			)}
		</div>
	</div>
)

const ConfirmationTemplate = (props) => {

  const settings = props.data.settings.edges[0].node

  return (
    <Layout image={settings.navImageAbout} theme={theme.red} settings={settings} location={props.location}>
      <SEO title="Time to act" />

			<div className="min-height-contents">
				<section className="prel border-top inner guttersx2 mbx4">
					<h2>Audiobook</h2>
					<p>The Audiobook links have also been sent to your email address.</p>
					<div className="outerx4">
						{LISTEN_LINKS.map((node, i) => (
							<DownloadLink {...node} key={`listen-link-${i}`} />
						))}
					</div>
				</section>

				<section className="prel border-top inner guttersx2">
					<h2>eBook</h2>
					<p>Click the links below to download the free Age of Union eBook.</p>
					<div className="outerx4">
						{EBOOK_LINKS.map((node, i) => (
							<DownloadLink {...node} key={`ebook-link-${i}`} />
						))}
					</div>
				</section>
			</div>
			
    </Layout>
  )
}

export default ConfirmationTemplate

const ConfirmationOld = (props) => {
  return(
    <div className="confirmation">
      <SEO title="Confirmation" />

      <div className="flex-column align-center mtx4">
        <h3>Time for change</h3>
        <div className="content innerx2">
          <p>The Audiobook links have also been <br/> sent to your email address. <br/><br/> Thank you</p>
        </div>
        <div className="download-links single-line innerx2">
          <a className="button" href="https://soundcloud.com/ageofunion/" target="_blank">SoundCloud</a>
          <a className="button" href="https://podcasts.apple.com/ca/podcast/age-of-union/id1527346168" target="_blank">Apple Podcasts</a>
          <a className="button" href="https://open.spotify.com/show/3HH5PUVP5MbsEHkGTsTi04" target="_blank">Spotify</a>
          <a className="button" href="https://www.stitcher.com/podcast/age-of-union" target="_blank">Stitcher</a>
          <a className="button" href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5zb3VuZGNsb3VkLmNvbS91c2Vycy9zb3VuZGNsb3VkOnVzZXJzOjg2MzEwMjMzOC9zb3VuZHMucnNz" target="_blank">Google Podcasts</a>
        </div>
        <div className="content separator innerx2">
          <p>eBook version is also available.<br/>Click the links below to download the free Age of Union eBook.</p>
        </div>
        <div className="download-links single-line innerx2">
          <a className="button" href="https://geni.us/FreeAgeOfUnioneBook" target="_blank">AMAZON KINDLE</a>
          <a className="button" href="https://books.apple.com/us/book/id1504008229" target="_blank">APPLE iBOOKS</a>
          <a className="button" href="https://play.google.com/store/books/details?id=eD7YDwAAQBAJ" target="_blank">GOOGLE PLAY</a>
        </div>
      </div>

      <footer>
        <ul>
          <li>Follow @ageofunion</li>
          <li>
            <a href="https://www.instagram.com/ageofunion/" alt="Age of Union's Instragram" target="_blank">Instagram</a>
          </li>
          <li>
            <a href="https://twitter.com/ageofunion" alt="Age of Union's Twitter" target="_blank">Twitter</a>
          </li>
          <li>
            <a href="https://www.facebook.com/ageofunion" alt="Age of Union's Facebook" target="_blank">Facebook</a>
          </li>
          <li>
            <a href="https://ageofunion.com/" alt="Age of Union" target="_blank">Continue to ageofunion.com</a>
          </li>
        </ul>
      </footer>
    </div>
  )
}