import React from 'react'

const front = 'https://images.ctfassets.net/1a7h0xku0wdm/Ypnw7oLaEZOtFeEy4AfAg/0b531112494d4582082b6c8b0fbb7fc9/AgeOfUnion_TradeBook_Cover_RGB_150DPI_201903128__1_.jpg?w=1000'

const Book = ({ homeLink }) => (
  <div className="cube-wrapper">
    <div className="cube-position flex-center">
      <div className="cube">
        <div className="front">
          <img src={front} alt='Igniting the Changemaker' />
        </div>
        <div className="back"></div>
        <div className="left" />
        <div className="right" />
        <div className="top" />
        <div className="bottom" />
      </div>
    </div>
  </div>
)

export default Book
